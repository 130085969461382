<template>
    <div class="row">
        <div class="col">
            <base-input :label="getTrans('messages.registration')"></base-input>
            <base-checkbox v-model="events.registration"></base-checkbox>
        </div>
        <div class="col">
            <base-input :label="getTrans('messages.sale')"></base-input>
            <base-checkbox v-model="events.sale"></base-checkbox>
        </div>
        <div class="col">
            <base-input :label="getTrans('messages.rebill')"></base-input>
            <base-checkbox v-model="events.rebill"></base-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <base-button @click="save">{{getTrans('messages.save')}}</base-button>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import Errors from "@/mixins/Errors";

export default {
    mixins:[Errors],
    data() {
        return {
            events: {},
            eventTypes: {
                registration:4,
                sale:3,
                rebill:5,
            },
            url: '',
            method: 'GET',
        }
    },
    computed: {
        ...mapGetters('Postback', {postbacks:'getData', errors: 'getErrors'})
    },
    methods: {
        save() {
            Object.keys(this.events).forEach(key => {
                if (this.events[key]) {
                    this.send(key);
                }
            });
        },
        send(event) {
            let promises = [];
            if (this.postbacks.filter(p=>p.event_type_id==this.eventTypes[event]).length) {
                promises.push(this.$store.dispatch('Postback/save'));
            } else {
                promises.push(this.$store.dispatch('Postback/create'));
            }
            Promise.all(promises).then(()=>{
                this.updateData();
            });
        },
        updateView() {
            
        },
        updateData() {
            this.$store.dispatch('Postback/get').then(()=>{
                this.updateView();
            });
        }
    },
    mounted() {
        this.updateData();
    }
}
</script>
